import React, { useEffect } from 'react';
import './App.css';
import About from './components/About/About';
import Connect from './components/Connect/Connect';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Project from './components/Projects/Project';
import Skill from './components/Skill/Skill';
import Aos from "aos";
import "aos/dist/aos.css";
import Footer from './components/Footer/Footer';
import Blog from './components/Blogs/Blog';
import ThemeProvider from './context/ThemeContext';
import { BrowserRouter as Router } from 'react-router-dom';
import Progressbar from './components/Progressbar/Progressbars';
import Scrolltotop from './components/Scrolltop/Scrolltotop';



function App() {
  //for animation
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Router>
      <ThemeProvider>
        <Header />
        <Progressbar/>
        <Home />
        <About />
        <Skill />
        <Project />
        <Blog />
        <Connect />
        <Footer />
        <Scrolltotop/>
      </ThemeProvider>
    </Router>
  );
}

export default App;
