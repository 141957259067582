import React from 'react';
import './Connectcard.css'

const ConnectCard = ({ icon, txt, link }) => {
  return (
    <div className="connect-card" data-aos="zoom-in">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={icon} alt={txt} />
        <p>{txt}</p>
      </a>
    </div>
  );
};

export default ConnectCard;