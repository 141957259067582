import React from 'react'
import "./Footer.css"
import { useTheme, useThemeUpdate } from '../../context/ThemeContext'

const Footer = () => {

    const darkTheme = useTheme();

    const themeStyle = {
        backgroundColor: darkTheme ? 'rgb(56,68,77)' : '#ffe6cc',
        color: darkTheme ? '#ffffff' : 'rgb(56,68,77)',
        // borderBottom: darkTheme ? '1px solid rgb(214,210,206)' : '#cf2200',
        borderTop: darkTheme ? '1px solid rgb(214,210,206)' : '2px solid rgb(207, 34, 0)',
    };

    return (
        <footer id='contact' style={themeStyle}>
            <div className="footer-content">
                {/* <h2>Contact</h2> */}
                <p>Reach out to me for collaboration, inquiries, or just to say hello!</p>
                <div className="copy">
                    <p>Made with ♥ Abhishek Kumar.</p>
                    <p>© 2024 My Website. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer