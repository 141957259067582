import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import './About.css';

const Education = () => {
  return (
    <>
      <div className="tab-contents" id="Education" data-aos="zoom-in">
        <br />
        <div className="institution">
          <p className="names1"><FontAwesomeIcon icon={faUniversity} /> RNSIT</p>
          <p className="year">2020-2024</p>
        </div>
        <p className="specializations">Bachelors Degree - ISE</p>
        <div className="institution">
          <p className="names1"><FontAwesomeIcon icon={faUniversity} /> Bihar Public School</p>
          <p className="year">2017-2019</p>
        </div>
        <p className="specializations">Intermediate</p>
        <div className="institution">
          <p className="names1"><FontAwesomeIcon icon={faUniversity} /> Bihar Public School</p>
          <p className="year">2016-2017</p>
        </div>
        <p className="specializations">Matriculation</p>
      </div>
    </>
  );
};

export default Education;
