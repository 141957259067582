import React from 'react';
import './Home.css';
// import Mypic from '../../assets/mypic.jpg'
import Mypic from '../../assets/Myheropic.jpg'
import { useTheme, useThemeUpdate } from '../../context/ThemeContext';
import { FaLinkedin, FaGithub, FaWhatsapp, FaTwitter } from 'react-icons/fa';

const Home = () => {
    const theme = useTheme();
    const themeStyle = {
        background: theme ? 'rgb(29, 42, 53)' : 'linear-gradient(0deg, #ffe6cc 0, #fff 50%, #ffe6cc 100%)',
        color: theme ? '#ffffff' : 'rgb(29, 42, 53)',
    };

    return (
        <section id="home" className="home-section" style={themeStyle}>
            <div className="home-content">
                <div className="text-content">
                    <h1 data-aos="fade-right">
                        Hi, I'm <span className='names'>Abhishek Kumar</span>
                    </h1>
                    <p data-aos="zoom-in">
                        I'm a <span className="auto-input">FullStack Web Developer</span>
                    </p>
                    <div>
                        <p>
                            As a computer science student, I'm passionate about the latest technology and love to build cool applications and write about web development and new features related to it, that are helpful to fellow developers.
                        </p>
                    </div>
                    <div className="buttons">
                        <a href="https://drive.google.com/file/d/11tz2JBDmNLPqTfsQtobCqReMo0YXAH-y/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                            <button className='homebutton'>Download CV</button>
                        </a>
                        <a href="#contact">
                            <button className='homebutton'>Contact Me</button>
                        </a>
                    </div>
                    <div className="social-buttons">
                        <a href="https://www.linkedin.com/in/abhishek-kumar-1409bb211" target="_blank" rel="noopener noreferrer" className="social-button linkedin">
                            <FaLinkedin />
                        </a>
                        <a href="https://github.com/abhii1213" target="_blank" rel="noopener noreferrer" className="social-button github">
                            <FaGithub />
                        </a>
                        <a href="https://wa.me/9142497834" target="_blank" rel="noopener noreferrer" className="social-button whatsapp">
                            <FaWhatsapp />
                        </a>
                        <a href="https://twitter.com/Abhii1213" target="_blank" rel="noopener noreferrer" className="social-button twitter">
                            <FaTwitter />
                        </a>
                    </div>
                </div>
                <div className="image-content" data-aos="zoom-in">
                    <img src={Mypic} alt="Abhishek Kumar" />
                </div>
            </div>
        </section>
    );
}

export default Home;