import React from 'react';
import SkillCard from './Skillcard';
import { skills } from '../../Data.js';
import './Skill.css';
import { useTheme, useThemeUpdate } from '../../context/ThemeContext';

const Skill = () => {
  const isDarkMode = useTheme();
  
  const themeStyle = {
    backgroundColor: isDarkMode ? "rgb(29,42,53)" : "#ffe6cc",
    paddingBottom: "120px",
  };

  const themeStyle1 = {
    backgroundColor: isDarkMode ? "rgb(29,42,53)" : "#ffe6cc",
    color: isDarkMode ? "white" : "black",
    // borderTop: isDarkMode ? "1px solid rgb(214,210,206)" : "1px solid #333",
    // borderBottom: isDarkMode ? "1px solid rgb(214,210,206)" : "1px solid #333",
  };

  return (
    <div style={themeStyle}>
      <div className="skills-section" id='skills' style={themeStyle1} data-aos="zoom-in-up" >
        <h3 className="skills-heading">SKILLS</h3>
        <div className="skills-container">
          {skills.map((skill, index) => (
            <SkillCard key={index} skill={skill} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skill;
