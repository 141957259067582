import React from 'react';
import BlogCard from './Blogcard';
import './Blogcard.css';
import { blogData } from '../../Data';
import { useTheme, useThemeUpdate } from '../../context/ThemeContext'

const Blog = () => {

  const themeStyle = {
    backgroundColor: useTheme() ? "rgb(29,42,53)" : "#ffe6cc",
    color: useTheme() ? "#ffffff" : "rgb(29,42,53)",
  };

  const themeStyle2 ={
    backgroundColor: useTheme() ? "rgb(56,68,77)" : "#ffe6cc",
    color: useTheme() ? "white" : "black",
    border: useTheme() ? "1px solid rgb(133 128 121)" : "white",
  }

  return (
    <div className='blogs0' style={themeStyle}>
      <section id='myblogs' >
      <div className='blogs'>
        <h2>BLOGS</h2>
        <div className="blog-container" style={themeStyle2}>
          <div className="blog-section" id="blog-container">
            {blogData.map((blog, index) => (
              <BlogCard key={index} {...blog} />
            ))}
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Blog;
