//icons
// import android from "./assets/icon/android.svg";
// import docker from "./assets/icon/docker.svg";
import css from "./assets/icon/css.svg";
import express from "./assets/icon/express.svg";
import git from "./assets/icon/git.svg";
import github from "./assets/icon/github.svg";
import html from "./assets/icon/html.svg";
import java from "./assets/icon/java.svg";
import javascript from "./assets/icon/javascript.svg";
import mongo from "./assets/icon/mongo.svg";
import node from "./assets/icon/nodejs.svg";
import python from "./assets/icon/python.svg";
import react_ic from "./assets/icon/react-ic.svg";
import reduxP from "./assets/icon/redux.svg";
// import typescript from "./assets/icon/typescript.svg";
// import sass from "./assets/icon/sass.svg";
import cpp from "./assets/icon/cpp.png";
import c from "./assets/icon/c.png";
import sql from "./assets/icon/database-solid.svg";
import bootstrap from "./assets/icon/bootstrap.svg";

//project images
import dbms_proj from "./assets/aero1.jpg";
import daa_proj from "./assets/multi-stage-graph.jpg";
import weather from "./assets/weather.jpg";
import news from "./assets/news.jpg";
import portfolio1 from "./assets/portfolio1.png";
import portfolio2 from "./assets/portfolio2.png";
import todo from "./assets/Todo.png";
import pass_generator from "./assets/pass_generator.png";
import food_web from "./assets/food_web.png";
import blogopedia from "./assets/blogopedia.png"
import themeChanger from "./assets/themeChanger.png"
import foodmart from "./assets/FoodMart.png"

//Blogs
import props from "./assets/react-props.png"
import asyns from "./assets/Asyns_await in JS.jpg"
import promises from "./assets/Javascript_promises.jpg"
import git_commands from "./assets/git_commands.jpg"
import trends from "./assets/Trends_in _Technology.jpg"
import useReducerImage from "./assets/useReducerImage.png"


//Connect icon
import Github from "./assets/icon/github.svg";
import Twitter from "./assets/icon/twitter.svg";
import LinkedIn from "./assets/icon/linkedin.svg";
import Hashnode from "./assets/icon/hashnode.svg";
import Email from "./assets/icon/gmail.svg";

const skills = [
    { blogImage: java, name: "Java" },
    { blogImage: c, name: "C" },
    { blogImage: cpp, name: "C++" },
    { blogImage: python, name: "Python" },
    { blogImage: html, name: "HTML5" },
    { blogImage: css, name: "CSS3" },
    { blogImage: javascript, name: "JavaScript" },
    { blogImage: bootstrap, name: "Bootstrap" },
    { blogImage: react_ic, name: "React" },
    { blogImage: reduxP, name: "Redux.js" },
    { blogImage: node, name: "Node.js" },
    { blogImage: express, name: "Express" },
    { blogImage: mongo, name: "MongoDB" },
    { blogImage: sql, name: "SQL" },
    { blogImage: git, name: "Git" },
    { blogImage: github, name: "Github" }
    
  ];

  //ptojects
  const projectData = [
    {
      id: 1,
      title: 'FOODMART',
      imageUrl: foodmart,
      description: 'Foodmart is a food ordering  web-based platform that allows customers to order food from restaurants. Customers can also browse menus and view all the dishes and order them.',
      technologies: ['Mongodb', 'Express JS', 'NodeJs', 'React.js','Bootstrap'],
      githubLink: 'https://github.com/abhii1213/FoodMart',
      liveDemoLink: 'https://deliciousfoodmart.vercel.app/',
    },
    {
      id: 2,
      title: 'BLOGOPEDIA',
      imageUrl: blogopedia,
      description: 'BlogoPedia is a dynamic blog website enabling users to create, write, and read blogs. With interactive features, users can engage by commenting on posts, fostering community interaction.',
      technologies: ['Mongodb', 'Express JS', 'NodeJs', 'React.js','Material UI'],
      githubLink: 'https://github.com/abhii1213/BlogoPedia',
      liveDemoLink: 'https://theblogopedia.vercel.app/',
    },
    {
      id: 3,
      title: 'Portfolio Website',
      imageUrl: portfolio2,
      description: 'This project is a personal website that showcases my work and skills as a developer.',
      technologies: ['JavaScript','ReactJs', 'CSS'],
      githubLink: 'https://github.com/abhii1213/portfolio_website_2',
      liveDemoLink: 'https://github.com/abhii1213/portfolio_website_2',
    },
    {
      id: 4,
      title: 'Weather App',
      imageUrl: weather,
      description: 'This project is a weather application built using HTML, CSS, and JavaScript to provide real-time weather updates andforecasts',
      technologies: ['HTML', 'CSS' , 'JavaScript'],
      githubLink: 'https://github.com/abhii1213/weather-app',
      liveDemoLink: 'https://github.com/abhii1213/weather-app',
    },
    {
      id: 5,
      title: 'Airport Passenger Management System',
      imageUrl: dbms_proj,
      description: 'It is a Database Project, designed and developed a web application with a back-end on PHP and SQL Database. Utilized HTML, CSS, and Bootstrap to have a user-friendly front-end.',
      technologies: ['HTML', 'CSS', 'JavaScript' , 'PHP' , 'MYSQL'],
      githubLink: 'https://github.com/abhii1213/Airport-passenger-management-system',
      liveDemoLink: 'https://github.com/abhii1213/Airport-passenger-management-system',
    },
    {
      id: 6,
      title: 'News Website',
      imageUrl: news,
      description: 'a dynamic news website using HTML, CSS, and JavaScript. Integrated with a news API to fetch and display real-time articles. mplemented navigation, search functionality, and responsive design for optimal user experience.',
      technologies: ['HTML', 'CSS', 'JavaScript'],
      githubLink: 'https://github.com/abhii1213/news_app',
      liveDemoLink: 'https://github.com/abhii1213/news_app',
    },
    {
      id: 7,
      title: 'Food Website',
      imageUrl: food_web,
      description: 'Designed and developed a dynamic food website using React.js, offering a modern and interactive platform for users toexplore culinary offerings. Implemented responsive design principles to ensure a seamless experience across various devices.',
      technologies: ['React.js', 'CSS', 'Bootstrap'],
      githubLink: 'https://github.com/abhii1213/food_website',
      liveDemoLink: 'https://foodcorn.netlify.app/',
    },
    {
      id: 8,
      title: 'Password Generator',
      imageUrl: pass_generator,
      description: 'Auto Password Generator',
      technologies: ['React.js', 'CSS'],
      githubLink: 'https://github.com/abhii1213/Password-Generator',
      liveDemoLink: 'https://github.com/abhii1213/Password-Generator',
    },
    // Add more projects as needed
  ];

  //blogs
  const blogData = [
    {
      title: 'UseReducer Hook',
      description: 'The useReducer hook in React is a more advanced alternative to the useState hook for managing complex state logic in your components . It is particularly useful .......',
      date: 'April 10, 2024',
      image: useReducerImage,
      link: 'https://abhi1213.hashnode.dev/usereducer-hook-in-reactjs',
    },
    {
      title: 'Props in React.js',
      description: 'In React, components are the building blocks of an applications user interface. They can be visual elements, entire sections of the UI, or even the application itself......',
      date: 'October 13, 2023',
      image: props,
      link: 'https://abhi1213.hashnode.dev/props-in-react',
    },
    {
      title: 'Async and Await in JS',
      description: 'Asynchronous programming plays a crucial role in achieving this responsiveness, and JavaScript provides several mechanisms for handling asynchronous operations. One of the most powerful and user-.....',
      date: 'January 21, 2024',
      image: asyns,
      link: 'https://abhi1213.hashnode.dev/mastering-asynchronous-javascript-with-asyncawait',
    },
    {
      title: 'JavaScript Promises',
      description: 'Promises provide a cleaner and more efficient way to handle asynchronous operations, reducing callback hell and improving code readability. In this blog post, we will explore the concept of JavaScri.....',
      date: 'January 21, 2024',
      image: promises,
      link: 'https://abhi1213.hashnode.dev/javascript-promises',
    },
    {
      title: 'Useful Git Commands ',
      description: 'GitHub, the worlds leading platform for version control and collaborative software development, is a crucial tool for developers and teams. While its web interface provides a user-friendly envir......',
      image: git_commands,
      link: 'https://abhi1213.hashnode.dev/github-commands',
    },
    {
      title: 'Future Technologies',
      description: 'In the dynamic world of technology, staying ahead of the curve is crucial for businesses and professionals alike. As we journey into the future, several emerging trends are reshaping the landscape......',
      date: 'January 21, 2024',
      image: trends,
      link: 'https://abhi1213.hashnode.dev/emerging-trends-in-technology',
    },
    {
      title: 'What is Props in React ?',
      description: ' In React, components are the building blocks of an applications user interface. They can be visual elements, entire sections of the UI, or even the application itself......',
      date: 'September 25, 2023',
      image: props,
      link: 'https://abhi1213.hashnode.dev/props-in-react',
    },
    // Add more blog data as needed
  ];

  //connect 
  const connectData = [
    { icon: Github, txt: "Github", link: "https://github.com/abhii1213" },
    {
      icon: LinkedIn,
      txt: "LinkedIn",
      link: "https://www.linkedin.com/in/abhishek-kumar-1409bb211",
    },
    {
      icon: Twitter,
      txt: "Twitter",
      link: "https://twitter.com/Abhii1213",
    },
    { icon: Hashnode, txt: "Hashnode", link: "https://abhi1213.hashnode.dev/" },
    { icon: Email, txt: "Email", link: "mailto:kabhi12132002@gmail.com" },
  ]; 


  export {skills, projectData , connectData ,blogData};