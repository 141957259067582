// import React from 'react';
// import './Projectcard.css';

// const ProjectCard = ({ title, imageUrl, description, technologies, githubLink, liveDemoLink }) => {
//   return (
//     <div className="project-card"  data-aos="zoom-in">
//       <img src={imageUrl} alt={title} className="project-image" />
//       <div className="project-details">
//         <h4>{title}</h4>
//         <p style={{textAlign:"centre" , fontSize:"14px" , textAlign:"center"}}>{description}</p>
//         <p>Technologies used: {technologies.join(', ')}</p>
//         <div className="project-links">
//           <a href={githubLink} className="github-link" target="_blank" rel="noopener noreferrer">
//             GitHub
//           </a>
//           <a href={liveDemoLink} className="live-link" target="_blank" rel="noopener noreferrer">
//             Live Demo
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProjectCard;

import React from 'react';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa'; // Importing icons from Font Awesome
import './Projectcard.css';

const ProjectCard = ({ title, imageUrl, description, technologies, githubLink, liveDemoLink }) => {
  return (
    <div className="project-card" data-aos="zoom-in">
      <img src={imageUrl} alt={title} className="project-image" />
      <div className="project-details">
        <h4>{title}</h4>
        <div className="project-hover-details">
          <p style={{ fontSize: "14px", textAlign: "center" }}>{description}</p>
          <p style={{ fontSize: "12px", textAlign: "center", color: "red" }}>Tech: {technologies.join(', ')}</p>
        </div>
        <div className="project-links">
          <a href={githubLink} className="github-link" target="_blank" rel="noopener noreferrer">
            <FaGithub /> {/* GitHub icon */}
          </a>
          <a href={liveDemoLink} className="live-link" target="_blank" rel="noopener noreferrer">
            <FaExternalLinkAlt /> {/* External link icon */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
