import React from 'react';
import './Blogcard.css';

const Blogcard = ({ title, description, date, image, link }) => {
  const openBlog = () => {
    window.open(link, '_blank');
  };

  return (
    <div className="blog-card" onClick={openBlog}>
      <div className="blog-content" data-aos="zoom-in">
        <h3>{title}</h3>
        <img src={image} alt={title} />
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Blogcard;
