import React, { useState } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Logo from '../../assets/mylogo.jpg';
import './Header.css'; // Create a CSS file for styling
import { useTheme, useThemeUpdate } from '../../context/ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const currentTheme = useTheme();

  const toggleMenu = (section) => {
    setShowMenu(!showMenu);
    setActiveSection(section);
  };

  const toggleTheme = useThemeUpdate();
  const themeStyle = {
    backgroundColor: useTheme() ? 'rgb(21,32,43)' : '#ffe6cc',
    // background: useTheme() ? 'rgb(21,32,43)' : 'linear-gradient(0deg, #ffe6cc 0, #fff 50%, #ffe6cc 100%)',
    color: useTheme() ? 'white' : 'black',
    borderBottom: useTheme() ? '1px solid white' : '1px solid white',
  };

  const iconColor = currentTheme ? 'white' : 'black';

  return (
    <nav className="navbar" style={themeStyle}>
      <div className="logo">
        <img src={Logo} alt="Logo" />
        <div className="brand">ABHISHEK</div>
      </div>
      <div className={`menu ${showMenu ? 'show' : ''}`}>
        <div className='links'>
          <ScrollLink
            to="home"
            smooth={true}
            duration={500}
            onClick={() => toggleMenu('home')}
            className={`links ${activeSection === 'home' ? 'active' : ''}`}
          >
            Home
          </ScrollLink>
        </div>
        <div className="links">
          <ScrollLink
            to="aboutme"
            smooth={true}
            duration={500}
            onClick={() => toggleMenu('aboutme')}
            className={`links ${activeSection === 'aboutme' ? 'active' : ''}`}
          >
            About
          </ScrollLink>
        </div>
        <div className="links">
          <ScrollLink
            to="skills"
            smooth={true}
            duration={500}
            onClick={() => toggleMenu('skills')}
            className={`links ${activeSection === 'skills' ? 'active' : ''}`}
          >
            Skills
          </ScrollLink>
        </div>
        <div className="links">
          <ScrollLink
            to="projects"
            smooth={true}
            duration={500}
            onClick={() => toggleMenu('projects')}
            className={`links ${activeSection === 'projects' ? 'active' : ''}`}
          >
            Projects
          </ScrollLink>
        </div>
        <div className="links">
          <ScrollLink
            to="myblogs"
            smooth={true}
            duration={500}
            onClick={() => toggleMenu('myblogs')}
            className={`links ${activeSection === 'myblogs' ? 'active' : ''}`}
          >
            Blogs
          </ScrollLink>
        </div>
        <div className="links">
          <ScrollLink
            to="contact"
            smooth={true}
            duration={500}
            onClick={() => toggleMenu('contact')}
            className={`links ${activeSection === 'contact' ? 'active' : ''}`}
          >
            Contact
          </ScrollLink>
        </div>

        {/* Add similar ScrollLink components for other sections */}
        {/* ... */}
        <div
          className="dark-mode-toggle"
          onClick={() => {
            toggleTheme();
            toggleMenu();
          }}
        >
          {useTheme() ? <FaSun size={30} /> : <FaMoon size={30} />}
        </div>
      </div>
      <div
        className="mobile-menu-icon"
        onClick={toggleMenu}
        style={{ fontSize: '30px' }}
      >
        {showMenu ? (
          <i className="fa fa-times" style={{ color: iconColor }}></i>
        ) : (
          <i className="fa fa-bars" style={{ color: iconColor }}></i>
        )}
      </div>
    </nav>
  );
};

export default Header;
