import React, { useState } from 'react';
import './About.css';
import Logo from '../../assets/about-me.svg';
import { useTheme } from '../../context/ThemeContext';
import Aboutme from './Aboutme';
import Education from './Education';

const About = () => {
  const darkTheme = useTheme();
  const [activeSection, setActiveSection] = useState('about');

  const themeStyle = {
    backgroundColor: darkTheme ? 'rgb(56,68,77)' : '#ffe6cc',
    color: darkTheme ? '#ffffff' : 'rgb(56,68,77)',
    borderBottom: darkTheme ? '1px solid rgb(214,210,206)' : 'white',
    borderTop: darkTheme ? '1px solid rgb(214,210,206)' : 'white',
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  return (
    <>
      <section id='aboutme' className="about-container" style={themeStyle}>
        <div className="about-heading-container">
          <h2 className="about-heading">ABOUT ME</h2>
        </div>

        <div className="edu_content">
          <div className="edu_content_1">
            {/* Navigation Links */}
            <div className="navigation-links">
              <button onClick={() => handleSectionChange('about')} className={`nav-link ${activeSection === 'about' && 'active-link'}`}>
                About
              </button>
              <button onClick={() => handleSectionChange('education')} className={`nav-link ${activeSection === 'education' && 'active-link'}`}>
                Education
              </button>
            </div>

            {/* Render About or Education component based on activeSection */}
            {activeSection === 'about' && <Aboutme />}
            {activeSection === 'education' && <Education />}
          </div>

          <div className="logo-container">
            <img src={Logo} alt="Logo" className="logo" />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
