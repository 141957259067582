import React from 'react';
import ConnectCard from './Connectcard';
import { connectData } from '../../Data'; // Assuming Data.js is in the same directory
import './Connectcard.css'
import { useTheme, useThemeUpdate } from '../../context/ThemeContext'

const Connect = () => {
  // const themeStyle = {
  //   backgroundColor: useTheme() ? "rgb(29,42,53)" : "#ffffff",
  //   color: useTheme() ? "#ffffff" : "rgb(29,42,53)",
  // };

  const themeStyle = {
    // background : useTheme() ? 'rgb(29,42,53)' : 'linear-gradient(315deg,#1a1d60 0,#027d88 100%)',
    background : useTheme() ? 'rgb(29,42,53)' : '#ffe6cc',
    color: useTheme() ? "#ffffff" : "White",
    borderBottom: useTheme() ? "1px solid rgb(214,210,206)" : "white",
    // borderTop: useTheme() ? "1px solid rgb(214,210,206)" : "white",
  }
  return (
    <div className='connect-container0' style={themeStyle}>
      <div className='connect-container' style={themeStyle}>
        <h2>Connect with me</h2>
        <div className="connect-section">
          {connectData.map((item, index) => (
            <ConnectCard
              key={index}
              icon={item.icon}
              txt={item.txt}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Connect;