import React from "react";

const ThemeContext = React.createContext();
const ThemeUpdateContext = React.createContext();
export function useTheme() {
  return React.useContext(ThemeContext);
}
export function useThemeUpdate() {
  return React.useContext(ThemeUpdateContext);
}
export default function ThemeProvider({ children }) {
  const [darkTheme, setDarkTheme] = React.useState(false);
  function toggleTheme() {
    setDarkTheme((prevDarkTheme) => !prevDarkTheme);
  }

  return (
    <ThemeContext.Provider value={darkTheme}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
}
