import React from 'react'
import './About.css'
const Aboutme = () => {
    return (
        <div className="about-content" data-aos="zoom-in">
            <br />
            <p className="about-description justify-paragraph">
                I am a 4th-year Information Science Engineering undergraduate at RNS Institute of Technology. My passion lies in web development and software engineering, and I've fortified my skills through various platforms like HackerRank, LeetCode, and GeeksforGeeks.
            </p>
            <p className="about-details justify-paragraph">
                I've tackled over 400 problems on these platforms, sharpening my problem-solving abilities. Proficient in C++, Python, and Java, I've also gained practical experience in web development through project work. With dedication and zeal, I've achieved a 5-star rating on HackerRank.
            </p>
        </div>

    )
}

export default Aboutme