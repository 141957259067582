import React from 'react';
import ProjectCard from './Projectcard';
import { projectData } from '../../Data'; // Assuming Data.js is in the same directory
import './Projectcard.css';
import { useTheme, useThemeUpdate } from '../../context/ThemeContext'

const Project = () => {
  const themeStyle = {
    backgroundColor: useTheme() ? "rgb(29,42,53)" : "#ffe6cc",
  };
  const themeStyle2 = {
    backgroundColor: useTheme() ? "rgb(56,68,77)" : "#ffe6cc",
    color: useTheme() ? "white" : "black",
    // border: useTheme() ? "1px solid rgb(214,210,206)" : "1px solid #fffff",
  }
  return (
    <section id='projects' style={themeStyle}>
      <div className="container" style={themeStyle2}>
        <h2 className="project-heading">PROJECTS</h2>
        <div className="project-container">
          {projectData.map((project) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              imageUrl={project.imageUrl}
              description={project.description}
              technologies={project.technologies}
              githubLink={project.githubLink}
              liveDemoLink={project.liveDemoLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Project;
