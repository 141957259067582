// skillcard.js

import React from 'react';

const SkillCard = ({ skill }) => {
  return (
    <div className="skill-card">
      <img src={skill.blogImage} alt={skill.name} className="skill-logo" />
      <h3 className="skill-name">{skill.name}</h3>
    </div>
  );
};

export default SkillCard;
