import React, { useEffect, useState } from "react";
import "./Progressbar.css";

const Progressbars = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const calculateScrollDistance = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const docHeight = getDocHeight();

      const totalDocScrollLength = docHeight - windowHeight;
      const scrollPosition = Math.floor(
        (scrollTop / totalDocScrollLength) * 100
      );

      setProgress(scrollPosition);
    };

    const getDocHeight = () => {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );
    };

    window.addEventListener("scroll", calculateScrollDistance);

    return () => {
      window.removeEventListener("scroll", calculateScrollDistance);
    };
  }, []);

  const handleClick = (event) => {
    const progressBar = event.target;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = event.clientX - rect.left;
    const progressBarWidth = rect.width;
    const newScrollPosition =
      (clickPosition / progressBarWidth) * document.body.scrollHeight;

    window.scrollTo({
      top: newScrollPosition,
      behavior: "smooth",
    });
  };

  return (
    <div className="ProgressBar" onClick={handleClick}>
      <div
        style={{
          width: `${progress}%`,
        }}
        className="ProgressBar__progress"
      />
    </div>
  );
};

export default Progressbars;